import type { SVGProps } from 'react';

const ReplyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
    className="fill-icon dark:fill-darkIconSecondary"
  >
    <path d="M13.667 1.667A1.334 1.334 0 0 0 12.333.333H1.667A1.333 1.333 0 0 0 .333 1.667v8A1.333 1.333 0 0 0 1.667 11H11l2.667 2.667v-12Z" />
  </svg>
);
export default ReplyIcon;
