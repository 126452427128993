import LoadingComment from './LoadingComment';
import LoadingCurrentComment from './LoadingCurrentComment';
import LoadingHeader from './LoadingHeader';
import LoadingItem from './LoadingItem';

interface IProps {
  section: string;
}

const LoadingSection = ({ section }: IProps) => {
  if (section === 'comment') {
    return <LoadingComment />;
  }
  if (section === 'currentComment') {
    return <LoadingCurrentComment />;
  }
  if (section === 'item') {
    return <LoadingItem />;
  }
  return <LoadingHeader />;
};

export default LoadingSection;
