import Image from 'next/image';

import type { IIcon } from '../CCR/types';
import { ICONS_WIDTH } from './constants';

const IconNotation = ({ icon, isDarkMode }: { icon: IIcon; isDarkMode: boolean }) => (
  <Image
    // this is to override Tailwind css for img height which causes different icon height
    style={{ height: '24px' }}
    src={`data:image/svg+xml;base64,${isDarkMode ? icon.image_source_dark : icon.image_source}`}
    alt="icon"
    height={24}
    width={ICONS_WIDTH[icon.name] || 60}
  />
);

export default IconNotation;
