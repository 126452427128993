'use client';

import { LABELS } from '@/utils/label';

interface IProps {
  isFetchingNextPage: boolean;
}

const LoadMore = ({ isFetchingNextPage = false }: IProps) => {
  if (!isFetchingNextPage) {
    return <div />;
  }
  return (
    <div className="flex flex-col items-center justify-center py-6">
      <svg className="-ml-1 mr-3 h-[24px] w-[24px] animate-spin text-textError" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
      <span className="mt-2">{LABELS.common.loading_records.long_name}</span>
    </div>
  );
};

export default LoadMore;
