import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';

import {
  formatToCurrency,
  formatToDecimal,
  formatToDecimalUS,
  formatToPercent,
  formatToShortened,
} from '@/utils/common';
import { formatDate, formatDateWithCustomDefault } from '@/utils/date';
import { LABELS } from '@/utils/label';
import { getSettings } from '@/utils/localStorage';

import type { IIcon, IPurchase } from '../CCR/types';
import CostDisplay from '../common/CostDisplay';
import Text from '../common/Text';
import Tooltip from '../common/tooltip';
import OverflowTooltip from '../common/tooltip/OverflowTooltip';
import TooltipPortal from '../common/tooltip/TooltipPortal';
import IconNotation from '../IconNotation';

interface Props {
  purchaseItem: IPurchase;
  isABC: boolean;
  showGPO: boolean;
  showPHS: boolean;
  showWAC: boolean;
}

const PurchaseItemComponent = ({ purchaseItem, isABC, showGPO, showPHS, showWAC }: Props) => {
  const [blendedCost, setBlendedCost] = useState<boolean>(getSettings('PurchasedItemSettings', 'blendedCost'));
  const [amu, setAmu] = useState<boolean>(getSettings('PurchasedItemSettings', 'amu'));
  const [vendor, setVendor] = useState<boolean>(getSettings('PurchasedItemSettings', 'vendor'));
  const [startDate, setStartDate] = useState<boolean>(getSettings('PurchasedItemSettings', 'startDate'));
  const [endDate, setEndDate] = useState<boolean>(getSettings('PurchasedItemSettings', 'endDate'));
  const [availQty, setAvailQty] = useState<boolean>(getSettings('PurchasedItemSettings', 'availQty'));
  const [priceSource, setPriceSource] = useState<boolean>(getSettings('PurchasedItemSettings', 'priceSource'));
  const [adjExtCost, setAdjExtCost] = useState<boolean>(getSettings('PurchasedItemSettings', 'adjExtCostV2'));
  const [purchQty, setPurchQty] = useState<boolean>(getSettings('PurchasedItemSettings', 'purchQty'));
  const [purchLastDate, setPurchLastDate] = useState<boolean>(getSettings('PurchasedItemSettings', 'purchLastDate'));
  const [currentWidth, setWidth] = useState<number | null>(null);

  const { systemTheme, theme } = useTheme();
  const currentTheme = theme === 'system' ? systemTheme : theme;

  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    if (currentTheme === 'dark') {
      setIsDarkMode(true);
    } else {
      setIsDarkMode(false);
    }
  }, [currentTheme]);

  // Listen to storage changes
  useEffect(() => {
    const handleExceptionData = () => {
      setBlendedCost(getSettings('PurchasedItemSettings', 'blendedCost'));
      setAmu(getSettings('PurchasedItemSettings', 'amu'));
      setVendor(getSettings('PurchasedItemSettings', 'vendor'));
      setStartDate(getSettings('PurchasedItemSettings', 'startDate'));
      setEndDate(getSettings('PurchasedItemSettings', 'endDate'));
      setAvailQty(getSettings('PurchasedItemSettings', 'availQty'));
      setPriceSource(getSettings('PurchasedItemSettings', 'priceSource'));
      setAdjExtCost(getSettings('PurchasedItemSettings', 'adjExtCostV2'));
      setPurchQty(getSettings('PurchasedItemSettings', 'purchQty'));
      setPurchLastDate(getSettings('PurchasedItemSettings', 'purchLastDate'));
    };
    setWidth(window?.innerWidth);
    window.addEventListener('storage', handleExceptionData);
    return () => {
      window.removeEventListener('storage', handleExceptionData);
    };
  }, []);

  const displayCost = (num: number | string | null | undefined) => {
    if (currentWidth !== null && currentWidth > 1825) {
      return <Text text={formatToCurrency(num)} size="sm" />;
    }

    return (
      <Tooltip content={formatToCurrency(num)} customClass="w-[125px]" enableHover={Number(num) > 9999}>
        <Text text={formatToShortened(num)} size="sm" />
      </Tooltip>
    );
  };

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <div className="flex h-full flex-col justify-between">
      <div>
        <div className="col-span-8 mb-2">
          <OverflowTooltip
            tooltipOptions={{
              placement: 'bottom',
              isCorner: true,
              isInlineBlock: false,
              content: purchaseItem.description,
            }}
            elementType="h2"
            contentClass="w-full font-extrabold overflow-hidden overflow-ellipsis whitespace-nowrap uppercase"
          />
        </div>
        <div className="col-span-8 grid grid-cols-8">
          <div className="col-span-3 max-[1366px]:col-span-4">
            <div>
              <Text text={`${LABELS.purchases.ndc.long_name}: `} size="sm" type="secondary" />
              <Text text={purchaseItem.ndc} size="sm" weight="semibold" />
            </div>
            <div>
              <Text text={`${LABELS.purchases.item_num.long_name}: `} size="sm" type="secondary" />
              <Text text={purchaseItem.item_num} size="sm" weight="semibold" />
            </div>
            <div>
              <Tooltip isCorner content={LABELS.purchases.total_qty.tooltip}>
                <Text text={`${LABELS.purchases.total_qty.long_name}: `} size="sm" type="secondary" />
              </Tooltip>
              <span className="ml-1">
                <Text text={purchaseItem.total_qty} size="sm" weight="semibold" />
              </span>
            </div>
            {amu && (
              <div>
                <Tooltip placement="right" customClass="w-[160px]" content={LABELS.purchases.amu.tooltip}>
                  <Text text={`${LABELS.purchases.amu.long_name}: `} size="sm" type="secondary" />
                </Tooltip>
                <span className="ml-1">
                  <Text text={formatToDecimalUS(purchaseItem.amu, 0)} size="sm" weight="semibold" />
                </span>
              </div>
            )}
            {isABC && availQty && (
              <div>
                <Text text={`${LABELS.purchases.avail_qty.long_name}: `} size="sm" type="secondary" />
                <Text text={formatToDecimalUS(purchaseItem.avail_qty, 0, true)} size="sm" weight="semibold" />
              </div>
            )}
          </div>
          <div className="col-span-5 max-[1366px]:col-span-4">
            <div>
              <Text text={`${LABELS.purchases.orange_book_rating.long_name}: `} size="sm" type="secondary" />
              <Text text={purchaseItem.orange_book_rating} size="sm" weight="semibold" />
            </div>
            {vendor && (
              <div className="flex gap-x-1">
                <Text text={`${LABELS.purchases.vendor.long_name}: `} size="sm" type="secondary" />
                <OverflowTooltip
                  tooltipOptions={{
                    placement: 'bottom',
                    isCorner: true,
                    isInlineBlock: false,
                    content: purchaseItem.vendor,
                    customDivClass: 'w-4/5',
                  }}
                  elementType="h3"
                  contentClass="text-textPrimary dark:text-darkTextPrimary font-semibold text-sm overflow-hidden overflow-ellipsis whitespace-nowrap"
                />
              </div>
            )}
            {startDate && (
              <div>
                <Text text={`${LABELS.purchases.start_date.long_name}: `} size="sm" type="secondary" />
                <Text text={formatDate(purchaseItem.start_date)} size="sm" weight="semibold" />
              </div>
            )}
            {endDate && (
              <div>
                <Text text={`${LABELS.purchases.end_date.long_name}: `} size="sm" type="secondary" />
                <Text text={formatDate(purchaseItem.end_date)} size="sm" weight="semibold" />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div className="grid min-h-[34px] grid-cols-12">
          <div className="flex flex-wrap items-center gap-1 max-[1919px]:col-span-6 min-[1920px]:col-span-7">
            {(purchaseItem.icons || []).map((icon: IIcon) => {
              return (
                <Tooltip
                  key={`Icon-${icon.id}-${purchaseItem.id}`}
                  content={icon.description}
                  customClass="text-center w-[103px]"
                >
                  <span>
                    <IconNotation {...{ icon, isDarkMode }} />
                  </span>
                </Tooltip>
              );
            })}
          </div>
          <div className="flex items-end justify-end max-[1919px]:col-span-6 min-[1920px]:col-span-5">
            {blendedCost && (
              <Tooltip content={LABELS.purchases.blended_ndc_cost.tooltip}>
                <CostDisplay
                  isPurchasedItem
                  price={formatToDecimal(purchaseItem.blended_ndc_cost)}
                  label={LABELS.purchases.blended_ndc_cost.long_name}
                />
              </Tooltip>
            )}
          </div>
        </div>
        <div className="col-span-2 overflow-auto">
          <table className="mt-4 w-full table-auto border border-lightBlue text-left  dark:border-darkBlue">
            <thead className="h-[65px] bg-lightBlue">
              <tr>
                <th className="whitespace-nowrap p-2 text-center align-top">
                  <Text text={LABELS.purchases.account_type.long_name} size="sm" weight="semibold" type="primary" />
                </th>
                {purchQty && (
                  <th className="whitespace-nowrap p-2 text-center align-top">
                    <div>
                      <Text text={LABELS.purchases.ndc_purch.short_name} size="sm" weight="semibold" type="primary" />
                    </div>
                    <div>
                      <Text text={LABELS.purchases.qty_month.short_name} size="sm" weight="semibold" type="primary" />
                    </div>
                  </th>
                )}
                {purchLastDate && (
                  <th className="whitespace-nowrap p-2 text-center align-top">
                    <div>
                      <Text text={LABELS.purchases.ndc_last.long_name} size="sm" weight="semibold" type="primary" />
                    </div>
                    <div>
                      <Text text={LABELS.purchases.purch_date.long_name} size="sm" weight="semibold" type="primary" />
                    </div>
                  </th>
                )}
                <th className="whitespace-nowrap p-2 text-center align-top">
                  <TooltipPortal content={LABELS.purchases.utilization.tooltip}>
                    <div>
                      <Text
                        text={LABELS.purchases.table_generic.long_name}
                        size="sm"
                        weight="semibold"
                        type="primary"
                      />
                    </div>
                    <div>
                      <Text
                        text={LABELS.purchases.util_percentage.long_name}
                        size="sm"
                        weight="semibold"
                        type="primary"
                      />
                    </div>
                  </TooltipPortal>
                </th>
                <th className="whitespace-nowrap p-2 text-center align-top">
                  <Text text={LABELS.purchases.cost.long_name} size="sm" weight="semibold" type="primary" />
                </th>
                {adjExtCost && (
                  <th className="whitespace-nowrap p-2 text-center align-top">
                    <TooltipPortal content={LABELS.purchases.adj_ext_cost.tooltip}>
                      <Text
                        text={LABELS.purchases.adj_ext_cost.short_name}
                        size="sm"
                        weight="semibold"
                        type="primary"
                      />
                    </TooltipPortal>
                  </th>
                )}
                {priceSource && (
                  <th className="whitespace-nowrap p-2 text-center align-top">
                    <Text text={LABELS.purchases.price_source.long_name} size="sm" weight="semibold" type="primary" />
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {showGPO && (
                <tr className="bg-white dark:bg-darkContentBg">
                  <td className="px-2 py-1 text-center">
                    <Text text={LABELS.purchases.gpo.long_name} size="sm" />
                  </td>
                  {purchQty && (
                    <td className="px-2 py-1 text-center">
                      <Text
                        text={
                          purchaseItem?.gpo_ndc_purch_qty ? formatToDecimalUS(purchaseItem.gpo_ndc_purch_qty, 0) : 0
                        }
                        size="sm"
                      />
                    </td>
                  )}
                  {purchLastDate && (
                    <td className="px-2 py-1 text-center">
                      <Text text={formatDateWithCustomDefault(purchaseItem.gpo_last_purch_date)} size="sm" />
                    </td>
                  )}
                  <td className="px-2 py-1 text-right">
                    <Text text={formatToPercent(purchaseItem.gpo_percentage, 0)} size="sm" />
                  </td>
                  <td className="px-2 py-1 text-right">{displayCost(purchaseItem.gpo_average_unit_price)}</td>
                  {adjExtCost && <td className="px-2 py-1 text-right">{displayCost(purchaseItem.gpo_ext)}</td>}
                  {priceSource && (
                    <td className="px-2 py-1 text-center">
                      <Text text={purchaseItem.gpo_average_unit_price_source} size="sm" />
                    </td>
                  )}
                </tr>
              )}
              {showPHS && (
                <tr className={showGPO ? 'bg-even dark:bg-darkEven' : 'bg-white dark:bg-darkContentBg'}>
                  <td className="px-2 py-1 text-center">
                    <Text text={LABELS.purchases.phs.long_name} size="sm" />
                  </td>
                  {purchQty && (
                    <td className="px-2 py-1 text-center">
                      <Text
                        text={purchaseItem.phs_ndc_purch_qty ? formatToDecimalUS(purchaseItem.phs_ndc_purch_qty, 0) : 0}
                        size="sm"
                      />
                    </td>
                  )}
                  {purchLastDate && (
                    <td className="px-2 py-1 text-center">
                      <Text text={formatDateWithCustomDefault(purchaseItem.phs_last_purch_date)} size="sm" />
                    </td>
                  )}

                  <td className="px-2 py-1 text-right">
                    <Text text={formatToPercent(purchaseItem.phs_percentage, 0)} size="sm" />
                  </td>
                  <td className="px-2 py-1 text-right">{displayCost(purchaseItem.phs_average_unit_price)}</td>
                  {adjExtCost && <td className="px-2 py-1 text-right">{displayCost(purchaseItem.phs_ext)}</td>}
                  {priceSource && (
                    <td className="px-2 py-1 text-center">
                      <Text text={purchaseItem.phs_average_unit_price_source} size="sm" />
                    </td>
                  )}
                </tr>
              )}
              {showWAC && (
                <tr
                  className={
                    (showGPO && showPHS) || (!showGPO && !showPHS)
                      ? 'bg-white dark:bg-darkContentBg'
                      : 'bg-even dark:bg-darkEven'
                  }
                >
                  <td className="px-2 py-1 text-center">
                    <Text text={LABELS.purchases.wac.long_name} size="sm" />
                  </td>
                  {purchQty && (
                    <td className="px-2 py-1 text-center">
                      <Text
                        text={purchaseItem.wac_ndc_purch_qty ? formatToDecimalUS(purchaseItem.wac_ndc_purch_qty, 0) : 0}
                        size="sm"
                      />
                    </td>
                  )}
                  {purchLastDate && (
                    <td className="px-2 py-1 text-center">
                      <Text text={formatDateWithCustomDefault(purchaseItem.wac_last_purch_date)} size="sm" />
                    </td>
                  )}
                  <td className="px-2 py-1 text-right">
                    <Text text={formatToPercent(purchaseItem.wac_percentage, 0)} size="sm" />
                  </td>
                  <td className="px-2 py-1 text-right">{displayCost(purchaseItem.wac_average_unit_price)}</td>
                  {adjExtCost && <td className="px-2 py-1 text-right">{displayCost(purchaseItem.wac_ext)}</td>}
                  {priceSource && (
                    <td className="px-2 py-1 text-center">
                      <Text text={purchaseItem.wac_average_unit_price_source} size="sm" />
                    </td>
                  )}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PurchaseItemComponent;
