'use client';

interface IProps {
  id: string;
  label: string;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
  checkboxPosition?: 'left' | 'right';
  labelClass?: string;
}

const Checkbox = ({
  id,
  label,
  disabled,
  onChange,
  isChecked,
  checkboxPosition = 'right',
  labelClass = '',
}: IProps) => {
  return (
    <div className="flex items-center">
      {checkboxPosition === 'left' && (
        <input
          id={id}
          onChange={onChange}
          type="checkbox"
          checked={isChecked}
          disabled={disabled}
          className="text-red-600 bg-gray-100 border-gray-300 focus:ring-red-500 mr-3 h-5 w-5 rounded focus:ring-2"
        />
      )}
      <label
        htmlFor={id}
        className={`text-gray-900 w-full py-4 text-base font-medium dark:text-darkTextPrimary ${labelClass}`}
      >
        {label}
      </label>
      {checkboxPosition === 'right' && (
        <input
          id={id}
          onChange={onChange}
          type="checkbox"
          checked={isChecked}
          disabled={disabled}
          className="text-red-600 bg-gray-100 border-gray-300 focus:ring-red-500 h-5 w-5 rounded focus:ring-2"
        />
      )}
    </div>
  );
};

export default Checkbox;
