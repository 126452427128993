'use client';

import dynamic from 'next/dynamic';
import { useState } from 'react';

import { formatDate, isPastDate } from '@/utils/date';
import { LABELS } from '@/utils/label';

import AccountInfo from '../AccountInfo';
import { WHOLESALER_TO_UPDATE } from '../CCR/constants';
import type { IAccount } from '../CCR/types';
import IconWithLabel from '../common/IconWithLabel';
import Text from '../common/Text';

const DynamicDownloadXlsx = dynamic(() => import('../DownloadXlsx'));
const DynamicListSettingsModal = dynamic(() => import('../ListSettingsModal'));

interface IProps {
  hospital?: string;
  gpo?: string;
  wholesaler?: string;
  startDate?: string | Date;
  endDate?: string | Date;
  responseDate?: string | undefined;
  code?: string;
  purchasesCount?: number;
  accounts: IAccount[];
}

const CCRHeading = ({
  hospital,
  gpo,
  wholesaler,
  startDate,
  endDate,
  responseDate,
  code,
  purchasesCount = 0,
  accounts,
}: IProps) => {
  const [showModalSettings, setShowModalSettings] = useState(false);

  return (
    <>
      <div className="z-10 w-full border-t-2 border-headingBg bg-white dark:border-[#212121]">
        <div className="flex w-full justify-between bg-white px-5 py-4 dark:bg-darkContentBg">
          <div className="flex flex-wrap items-center gap-5">
            <div className="border-r-2 border-solid border-headingBg pr-5 dark:border-darkBorderSecondary">
              <div>
                <Text text={`${LABELS.reports.hospital.long_name}: `} type="secondary" />
                <Text text={hospital} weight="bold" />
                <AccountInfo accounts={accounts} />
              </div>
            </div>
            <div className="border-r-2 border-solid border-headingBg pr-5 dark:border-darkBorderSecondary">
              <div>
                <Text text={`${LABELS.purchases.gpo.long_name}: `} type="secondary" />
                <Text text={gpo} weight="bold" />
              </div>
            </div>
            <div className="border-r-2 border-solid border-headingBg pr-5 dark:border-darkBorderSecondary">
              <div>
                <Text text={`${LABELS.purchases.wholesaler.long_name}: `} type="secondary" />
                <Text text={wholesaler} weight="bold" />
              </div>
            </div>
            <div className="pr-5">
              <div className="flex items-center justify-center whitespace-nowrap">
                <Text text={`${LABELS.reports.review_date.long_name}: `} type="secondary" />
                <div className="ml-1">
                  <Text text={` ${formatDate(startDate)} - ${formatDate(endDate)}`} weight="bold" />
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-x-3">
            {/* Download xlsx */}
            {!!purchasesCount && <DynamicDownloadXlsx code={code} />}

            <IconWithLabel label="Settings" icon="settings" handleClick={() => setShowModalSettings(true)} />
          </div>
        </div>

        <div className="flex flex-row-reverse">
          {responseDate ? (
            isPastDate(responseDate) ? (
              <div className="absolute top-4 mr-6 rounded bg-lightRed px-4 py-2 dark:bg-darkRed">
                <Text
                  text={`${LABELS.reports.response_date.long_name} `}
                  customClass="dark:text-white"
                  type="secondary"
                  weight="semibold"
                  size="xl"
                />
                <Text text={`${formatDate(responseDate)}`} type="error" weight="semibold" size="xl" />
              </div>
            ) : (
              <div className="absolute top-4 mr-6 rounded bg-lightGreen px-4 py-2 dark:bg-darkLightGreen">
                <Text
                  text={LABELS.reports.future_response_date.long_name}
                  customClass="dark:text-white"
                  type="secondary"
                  weight="semibold"
                  size="xl"
                />
                <Text text={` ${formatDate(responseDate)}`} type="success" weight="semibold" size="xl" />
              </div>
            )
          ) : (
            <div className="absolute top-4 mr-6 rounded bg-lightGreen px-4 py-2 dark:bg-darkLightGreen">
              <Text
                text={LABELS.reports.response_date_open.long_name}
                customClass="dark:text-white"
                type="secondary"
                weight="semibold"
                size="xl"
              />
            </div>
          )}
        </div>
      </div>

      {/* List Settings */}
      {showModalSettings && (
        <DynamicListSettingsModal
          {...{
            isABC: wholesaler === WHOLESALER_TO_UPDATE,
            showModal: showModalSettings,
            setShowModal: setShowModalSettings,
          }}
        />
      )}
    </>
  );
};

export default CCRHeading;
