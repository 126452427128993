import React, { useEffect, useRef, useState } from 'react';

import type { TooltipProps } from '.';
import Tooltip from '.';

interface IProps {
  tooltipOptions: TooltipProps;
  contentClass: string;
  elementType: 'h2' | 'h3';
}
const OverflowTooltip = ({ tooltipOptions, contentClass, elementType }: IProps) => {
  const [isOverflowed, setIsOverflowed] = useState(false);
  const contentRef = useRef<any>();

  useEffect(() => {
    setIsOverflowed(contentRef?.current?.scrollWidth > contentRef?.current?.clientWidth);
  }, [contentRef?.current?.scrollWidth]);

  const HeadingTag = React.createElement(
    elementType,
    { className: contentClass, ref: contentRef },
    tooltipOptions.content
  );

  return (
    <Tooltip {...tooltipOptions} enableHover={isOverflowed}>
      {HeadingTag}
    </Tooltip>
  );
};

export default OverflowTooltip;
