import classnames from 'classnames';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';

import { formatToCurrency, formatToDecimal, formatToDecimalUS, formatToShortened } from '@/utils/common';
import { formatDateWithCustomDefault } from '@/utils/date';
import { LABELS } from '@/utils/label';
import { getSettings } from '@/utils/localStorage';

import type { IConversion, IIcon } from '../CCR/types';
import CostDisplay from '../common/CostDisplay';
import Text from '../common/Text';
import Tooltip from '../common/tooltip';
import OverflowTooltip from '../common/tooltip/OverflowTooltip';
import TooltipPortal from '../common/tooltip/TooltipPortal';
import IconNotation from '../IconNotation';

interface IProps {
  item: IConversion;
  showBlockReason?: boolean;
  isABC: boolean;
  showGPO: boolean;
  showPHS: boolean;
  showWAC: boolean;
}

const ConversionItem = ({ item, showBlockReason = false, isABC, showGPO, showPHS, showWAC }: IProps) => {
  const [ranking, setRanking] = useState<boolean>(getSettings('ConversionRecommendationSettings', 'ranking'));
  const [blendedCost, setBlendedCost] = useState<boolean>(
    getSettings('ConversionRecommendationSettings', 'blendedCost')
  );
  const [amu, setAmu] = useState<boolean>(getSettings('ConversionRecommendationSettings', 'amu'));
  const [vendor, setVendor] = useState<boolean>(getSettings('ConversionRecommendationSettings', 'vendor'));
  const [dcCode, setDcCode] = useState<boolean>(getSettings('ConversionRecommendationSettings', 'dcCode'));
  const [multiplier, setMultiplier] = useState<boolean>(getSettings('ConversionRecommendationSettings', 'multiplier'));
  const [availQty, setAvailQty] = useState<boolean>(getSettings('ConversionRecommendationSettings', 'availQty'));
  const [priceSource, setPriceSource] = useState<boolean>(
    getSettings('ConversionRecommendationSettings', 'priceSource')
  );
  const [adjExtCost, setAdjExtCost] = useState<boolean>(
    getSettings('ConversionRecommendationSettings', 'adjExtCostV2')
  );
  const [blockReason, setBlockReason] = useState<boolean>(
    getSettings('ConversionRecommendationSettings', 'blockReason')
  );
  const [purchQty, setPurchQty] = useState<boolean>(getSettings('ConversionRecommendationSettings', 'purchQty'));
  const [purchLastDate, setPurchLastDate] = useState<boolean>(
    getSettings('ConversionRecommendationSettings', 'purchLastDate')
  );
  const [currentWidth, setWidth] = useState<number | null>(null);

  // Listen to storage changes
  useEffect(() => {
    const handleExceptionData = () => {
      setRanking(getSettings('ConversionRecommendationSettings', 'ranking'));
      setBlendedCost(getSettings('ConversionRecommendationSettings', 'blendedCost'));
      setAmu(getSettings('ConversionRecommendationSettings', 'amu'));
      setVendor(getSettings('ConversionRecommendationSettings', 'vendor'));
      setDcCode(getSettings('ConversionRecommendationSettings', 'dcCode'));
      setMultiplier(getSettings('ConversionRecommendationSettings', 'multiplier'));
      setAvailQty(getSettings('ConversionRecommendationSettings', 'availQty'));
      setPriceSource(getSettings('ConversionRecommendationSettings', 'priceSource'));
      setAdjExtCost(getSettings('ConversionRecommendationSettings', 'adjExtCostV2'));
      setBlockReason(getSettings('ConversionRecommendationSettings', 'blockReason'));
      setPurchQty(getSettings('ConversionRecommendationSettings', 'purchQty'));
      setPurchLastDate(getSettings('ConversionRecommendationSettings', 'purchLastDate'));
    };
    setWidth(window?.innerWidth);
    window.addEventListener('storage', handleExceptionData);
    return () => {
      window.removeEventListener('storage', handleExceptionData);
    };
  }, []);

  const { systemTheme, theme } = useTheme();
  const currentTheme = theme === 'system' ? systemTheme : theme;

  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    if (currentTheme === 'dark') {
      setIsDarkMode(true);
    } else {
      setIsDarkMode(false);
    }
  }, [currentTheme]);

  const hasVendor = classnames('absolute right-0 flex flex-col items-end', {
    'top-[-35px]': item.description,
  });

  const displayCost = (num: number | string | null | undefined) => {
    if (currentWidth !== null && currentWidth > 1825) {
      return <Text text={formatToCurrency(num)} size="sm" />;
    }

    return (
      <Tooltip content={formatToCurrency(num)} customClass="w-[125px]" enableHover={Number(num) > 9999}>
        <Text text={formatToShortened(num)} size="sm" />
      </Tooltip>
    );
  };

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <>
      <div className="grid grid-cols-8">
        <div className="col-span-8 mb-2">
          <OverflowTooltip
            tooltipOptions={{
              placement: 'bottom',
              isCorner: true,
              isInlineBlock: false,
              content: item.description,
            }}
            elementType="h2"
            contentClass="w-full font-extrabold 2xl:w-7/12 overflow-hidden overflow-ellipsis whitespace-nowrap uppercase"
          />
        </div>
        <div className="max-2xl:hidden 2xl:relative 2xl:col-span-8">
          <div className={hasVendor}>
            <CostDisplay
              price={item.potential_savings}
              label={LABELS.conversions.potential_savings.long_name}
              isPotSavings
              isPurchasedItem={false}
            />
            {ranking && (
              <div className="rank">
                <Text
                  text={`${LABELS.conversions.ranking.long_name} #${item.ranking}`}
                  size="sm"
                  weight="semibold"
                  type="success"
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-span-8 grid grid-cols-12">
          <div className="col-span-12 grid grid-cols-2 2xl:col-span-8 2xl:grid-cols-8">
            <div className="2xl:col-span-4">
              <div>
                <Text text={`${LABELS.conversions.ndc.long_name}: `} size="sm" type="secondary" />
                <Text text={item.ndc} size="sm" weight="semibold" />
              </div>
              <div>
                <Text text={`${LABELS.conversions.item_num.long_name}: `} size="sm" type="secondary" />
                <Text text={item.item_num} size="sm" weight="semibold" />
              </div>
              {multiplier && (
                <div>
                  <Text text={`${LABELS.conversions.multiplier.long_name}: `} size="sm" type="secondary" />
                  <Text text={formatToDecimal(item.multiplier)} size="sm" weight="semibold" />
                </div>
              )}
              {amu && (
                <div>
                  <Tooltip placement="right" customClass="w-[160px]" content={LABELS.purchases.amu.tooltip}>
                    <Text text={`${LABELS.purchases.amu.long_name}: `} size="sm" type="secondary" />
                  </Tooltip>
                  <Text text={formatToDecimalUS(item.amu, 0)} size="sm" weight="semibold" />
                </div>
              )}
              {isABC && availQty && (
                <div>
                  <Text text={`${LABELS.purchases.avail_qty.long_name}: `} size="sm" type="secondary" />
                  <Text text={formatToDecimalUS(item.avail_qty, 0, true)} size="sm" weight="semibold" />
                </div>
              )}
            </div>
            <div className="2xl:col-span-4">
              <div>
                <Text text={`${LABELS.conversions.orange_book_rating.long_name}: `} size="sm" type="secondary" />
                <Text text={item.orange_book_rating} size="sm" weight="semibold" />
              </div>
              {vendor && (
                <div className="flex gap-x-1">
                  <Text text={`${LABELS.conversions.vendor.long_name}: `} size="sm" type="secondary" />
                  <OverflowTooltip
                    tooltipOptions={{
                      placement: 'bottom',
                      isCorner: true,
                      isInlineBlock: false,
                      content: item.vendor,
                      customDivClass: 'w-4/5',
                    }}
                    elementType="h3"
                    contentClass="text-textPrimary dark:text-darkTextPrimary font-semibold text-sm overflow-hidden overflow-ellipsis whitespace-nowrap"
                  />
                </div>
              )}
              {dcCode && (
                <>
                  <div>
                    <Tooltip placement="bottom" content={LABELS.conversions.dc_code.tooltip}>
                      <Text text={`${LABELS.conversions.dc_availability.long_name}: `} size="sm" type="secondary" />
                    </Tooltip>
                  </div>
                  {(item.dc_history_note_1 || item.dc_history_note_2 || item.dc_history_note_3) && (
                    <div className="w-full whitespace-pre-line pl-6">
                      {item.dc_history_note_1 ? (
                        <>
                          <Text text={item.dc_history_note_1} size="sm" weight="semibold" />
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      {item.dc_history_note_2 ? (
                        <>
                          <Text text={item.dc_history_note_2} size="sm" weight="semibold" />
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      {item.dc_history_note_3 ? <Text text={item.dc_history_note_3} size="sm" weight="semibold" /> : ''}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="col-span-5">
            {showBlockReason && blockReason && (
              <>
                <Text text={`${LABELS.conversions.block_reason.long_name}: `} size="sm" type="secondary" />
                <Text text={item.block_reason} size="sm" weight="semibold" />
              </>
            )}
          </div>
        </div>

        <div className="col-span-8 2xl:hidden">
          <div className="flex flex-row-reverse gap-4">
            <CostDisplay
              price={item.potential_savings}
              label={LABELS.conversions.potential_savings.long_name}
              isPotSavings
              isPurchasedItem={false}
              isXlSize
            />
            {ranking && (
              <div className="rank flex h-8 items-center rounded-md">
                <Text
                  text={`${LABELS.conversions.ranking.long_name} #${item.ranking}`}
                  size="sm"
                  weight="semibold"
                  type="success"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="grid min-h-[34px] grid-cols-12">
          <div className="flex flex-wrap items-center gap-1 max-[1919px]:col-span-6 min-[1920px]:col-span-7">
            {(item.icons || []).map((icon: IIcon) => {
              return (
                <Tooltip
                  key={`Icon-${icon.id}-${item.id}`}
                  content={icon.description}
                  customClass="text-center w-[103px]"
                >
                  <span>
                    <IconNotation {...{ icon, isDarkMode }} />
                  </span>
                </Tooltip>
              );
            })}
          </div>
          <div className="flex items-end justify-end max-[1919px]:col-span-6 min-[1920px]:col-span-5">
            {blendedCost && (
              <Tooltip content={LABELS.purchases.blended_ndc_cost.tooltip}>
                <CostDisplay
                  isPurchasedItem={false}
                  price={formatToDecimal(item.blended_ndc_cost)}
                  label={LABELS.purchases.blended_ndc_cost.long_name}
                />
              </Tooltip>
            )}
          </div>
        </div>
        <div className="col-span-2">
          <table className="mt-4 w-full border border-lightGreen text-left dark:border-darkLightGreen">
            <thead className="bg-lightGreen dark:bg-darkLightGreen h-[65px]">
              <tr>
                {purchQty && (
                  <th className="p-2 text-center align-top">
                    <div>
                      <Text text={LABELS.conversions.ndc_purch.short_name} size="sm" weight="semibold" type="success" />
                    </div>
                    <div>
                      <Text text={LABELS.conversions.qty_month.short_name} size="sm" weight="semibold" type="success" />
                    </div>
                  </th>
                )}
                {purchLastDate && (
                  <th className="p-2 text-center align-top">
                    <div>
                      <Text text={LABELS.conversions.ndc_last.short_name} size="sm" weight="semibold" type="success" />
                    </div>
                    <div>
                      <Text
                        text={LABELS.conversions.purch_date.short_name}
                        size="sm"
                        weight="semibold"
                        type="success"
                      />
                    </div>
                  </th>
                )}
                <th className="p-2 text-center align-top">
                  <Text text={LABELS.purchases.cost.long_name} size="sm" weight="semibold" type="success" />
                </th>
                {adjExtCost && (
                  <th className="p-2 text-center align-top">
                    <TooltipPortal content={LABELS.purchases.adj_ext_cost.tooltip}>
                      <Text
                        text={LABELS.purchases.adj_ext_cost.short_name}
                        size="sm"
                        weight="semibold"
                        type="success"
                      />
                    </TooltipPortal>
                  </th>
                )}
                {priceSource && (
                  <th className="p-2 text-center align-top">
                    <Text text={LABELS.purchases.price_source.long_name} size="sm" weight="semibold" type="success" />
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {showGPO && (
                <tr className="bg-white dark:bg-darkContentBg">
                  {purchQty && (
                    <td className="px-2 py-1 text-center">
                      <Text
                        text={item.gpo_ndc_purch_qty ? formatToDecimalUS(item.gpo_ndc_purch_qty, 0) : 0}
                        size="sm"
                      />
                    </td>
                  )}
                  {purchLastDate && (
                    <td className="px-2 py-1 text-center">
                      <Text text={formatDateWithCustomDefault(item.gpo_last_purch_date)} size="sm" />
                    </td>
                  )}
                  <td className="px-2 py-1 text-right">{displayCost(item.gpo_adj_unit_price)}</td>
                  {adjExtCost && <td className="px-2 py-1 text-right">{displayCost(item.gpo_adj_ext)}</td>}
                  {priceSource && (
                    <td className="px-2 py-1 text-center">
                      <Text text={item.gpo_unit_price_source} size="sm" />
                    </td>
                  )}
                </tr>
              )}
              {showPHS && (
                <tr className={showGPO ? 'bg-even dark:bg-darkEven' : 'bg-white dark:bg-darkContentBg'}>
                  {purchQty && (
                    <td className="px-2 py-1 text-center">
                      <Text
                        text={item.phs_ndc_purch_qty ? formatToDecimalUS(item.phs_ndc_purch_qty, 0) : 0}
                        size="sm"
                      />
                    </td>
                  )}
                  {purchLastDate && (
                    <td className="px-2 py-1 text-center">
                      <Text text={formatDateWithCustomDefault(item.phs_last_purch_date)} size="sm" />
                    </td>
                  )}
                  <td className="px-2 py-1 text-right">{displayCost(item.phs_adj_unit_price)}</td>
                  {adjExtCost && <td className="px-2 py-1 text-right">{displayCost(item.phs_adj_ext)}</td>}
                  {priceSource && (
                    <td className="px-2 py-1 text-center">
                      <Text text={item.phs_unit_price_source} size="sm" />
                    </td>
                  )}
                </tr>
              )}
              {showWAC && (
                <tr
                  className={
                    (showGPO && showPHS) || (!showGPO && !showPHS)
                      ? 'bg-white dark:bg-darkContentBg'
                      : 'bg-even dark:bg-darkEven'
                  }
                >
                  {purchQty && (
                    <td className="px-2 py-1 text-center">
                      <Text
                        text={item.wac_ndc_purch_qty ? formatToDecimalUS(item.wac_ndc_purch_qty, 0) : 0}
                        size="sm"
                      />
                    </td>
                  )}
                  {purchLastDate && (
                    <td className="px-2 py-1 text-center">
                      <Text text={formatDateWithCustomDefault(item.wac_last_purch_date)} size="sm" />
                    </td>
                  )}
                  <td className="px-2 py-1 text-right">{displayCost(item.wac_adj_unit_price)}</td>
                  {adjExtCost && <td className="px-2 py-1 text-right">{displayCost(item.wac_adj_ext)}</td>}
                  {priceSource && (
                    <td className="px-2 py-1 text-center">
                      <Text text={item.wac_unit_price_source} size="sm" />
                    </td>
                  )}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ConversionItem;
