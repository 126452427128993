import { useState } from 'react';

import { LABELS } from '@/utils/label';

import type { IAccount } from '../CCR/types';
import Button from '../common/Button';
import PortalModal from '../common/Modal/PortalModal';
import type { ColumnHeader } from '../common/Table';
import Table from '../common/Table';
import Text from '../common/Text';

interface IProps {
  accounts: IAccount[];
}

const columnHeaders: ColumnHeader[] = [
  { key: 'acct_num', title: 'Account Number', type: 'string' },
  { key: 'acct_name', title: 'Account Name', type: 'string' },
  { key: 'dea', title: 'DEA', type: 'string' },
  { key: 'gpo', title: 'Account Type', type: 'string' },
  { key: 'cot', title: 'Class of Trade', type: 'string' },
  { key: 'wholesaler', title: 'Wholesaler', type: 'string' },
  { key: 'client_price_account', title: 'Client Price Source', type: 'boolean', align: 'center' },
];

const AccountInfo = ({ accounts }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        btnClass="ml-2 px-[12px] py-[5px] text-sm bg-white border border-borderSecondary dark:bg-darkContentBg dark:border-darkTextPrimary"
        onClick={() => setIsOpen(true)}
      >
        <Text text={LABELS.reports.account_info.short_name} size="sm" weight="semibold" />
      </Button>
      <PortalModal isOpen={isOpen} onClose={() => setIsOpen(false)} title={LABELS.reports.account_info.long_name}>
        <Table keyString="id" columnHeaders={columnHeaders} data={accounts} />
      </PortalModal>
    </>
  );
};

export default AccountInfo;
