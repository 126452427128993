import logger from './logger';

export const getSession = (key: string) => {
  if (typeof window !== 'undefined') {
    try {
      const serializedState = sessionStorage.getItem(key);
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (error: any) {
      logger.logEvent(
        'error',
        'utils/sessionStorage',
        `Failed to get sessionStorage item for ${key}`,
        error.message,
        error
      );
      return undefined;
    }
  }
};

export const saveSession = (key: string, value: string) => {
  if (typeof window !== 'undefined') {
    try {
      const serializedState = JSON.stringify(value);
      sessionStorage.setItem(`${key}`, serializedState);
    } catch (error: any) {
      logger.logEvent(
        'error',
        'utils/sessionStorage',
        `Failed to set sessionStorage item for ${key}`,
        error.message,
        error
      );
      return undefined;
    }
  }
};
