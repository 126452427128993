import type { SVGProps } from 'react';

const OpenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
    className="fill-icon dark:fill-darkIconSecondary"
  >
    <path d="M 1.828125 12.007812 L 1.300781 11.785156 C 0.976562 11.648438 0.761719 11.414062 0.652344 11.078125 C 0.542969 10.742188 0.5625 10.410156 0.707031 10.082031 L 1.828125 7.621094 Z M 4.320312 13.394531 C 3.976562 13.394531 3.683594 13.273438 3.4375 13.023438 C 3.195312 12.777344 3.074219 12.480469 3.074219 12.132812 L 3.074219 8.347656 L 4.722656 12.984375 C 4.753906 13.058594 4.785156 13.128906 4.816406 13.199219 C 4.847656 13.265625 4.890625 13.332031 4.941406 13.394531 Z M 7.523438 13.332031 C 7.191406 13.457031 6.871094 13.441406 6.558594 13.285156 C 6.25 13.128906 6.03125 12.878906 5.90625 12.542969 L 3.136719 4.847656 C 3.011719 4.511719 3.023438 4.183594 3.167969 3.859375 C 3.3125 3.539062 3.550781 3.320312 3.882812 3.207031 L 8.941406 1.25 C 9.273438 1.125 9.59375 1.140625 9.90625 1.296875 C 10.21875 1.457031 10.433594 1.703125 10.558594 2.039062 L 13.332031 9.738281 C 13.453125 10.074219 13.445312 10.402344 13.300781 10.722656 C 13.152344 11.042969 12.914062 11.261719 12.582031 11.375 Z M 7.523438 13.332031 " />
  </svg>
);
export default OpenIcon;
