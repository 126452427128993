'use client';

import classnames from 'classnames';

import { formatToCurrency } from '@/utils/common';

interface IProps {
  className?: string;
  isPurchasedItem?: boolean;
  labelClass?: string;
  price: string | number;
  label: string;
  isPotSavings?: boolean;
  isXlSize?: boolean;
}

const CostDisplay = ({
  className = '',
  isPurchasedItem = false,
  price,
  label,
  labelClass = '',
  isPotSavings = false,
  isXlSize = false,
}: IProps) => {
  const potSavingsClass = classnames(
    `text-rightborder-2 flex flex-row items-center justify-end rounded-md border-0 px-4 py-2 2xl:border`,
    className,
    {
      'border-borderPrimary bg-green text-white dark:border-transparent': isPotSavings && !isPurchasedItem,
      'border-borderPrimary bg-lightBlue dark:border-blue dark:bg-transparent': isPurchasedItem && !isPotSavings,
      'border-borderPrimary bg-lightGreen dark:border-green dark:bg-transparent': !isPotSavings && !isPurchasedItem,
      'mt-2 h-8': isXlSize,
    }
  );

  const labelTxt = classnames({
    'text-sm font-medium text-white': isPotSavings && !isPurchasedItem,
    'text-sm font-semibold text-blue': isPurchasedItem && !isPotSavings,
    'text-sm font-semibold text-textSuccess': !isPotSavings && !isPurchasedItem,
  });

  const priceTxt = classnames({
    'text-base text-white': isPotSavings && !isPurchasedItem,
    'text-sm text-textBlue dark:text-blue': isPurchasedItem && !isPotSavings,
    'text-sm text-textSuccess dark:text-textSuccess': !isPotSavings && !isPurchasedItem,
  });

  return (
    <div className={`${potSavingsClass}`}>
      <span className={` ${labelClass} ${labelTxt}`}>{label}:</span>
      <h3 className={`ml-2 font-semibold ${priceTxt}`}>{formatToCurrency(price)}</h3>
    </div>
  );
};

export default CostDisplay;
