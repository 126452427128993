const CheckIcon = () => (
  <svg className="mr-3 h-[16px] w-[16px]" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.99998 10.78L3.21998 8.00002L2.27332 8.94002L5.99998 12.6667L14 4.66668L13.06 3.72668L5.99998 10.78Z"
      fill="#2AA662"
    />
  </svg>
);

export default CheckIcon;
