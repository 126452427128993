import LoadingSection from '@/components/common/LoadingSection';
import Text from '@/components/common/Text';
import { LABELS } from '@/utils/label';

/**
 * TODO
 * add skeleton loading style
 */
export const ItemLoading = () => (
  <div className="mx-10 mt-2 grid grid-cols-12 rounded">
    <div className="col-span-5 mr-2 rounded-md bg-white p-4 dark:bg-darkContentBg">
      <LoadingSection section="item" />
    </div>
    <div className="col-span-5 mr-2 rounded-md bg-white p-4 dark:bg-darkContentBg">
      <LoadingSection section="item" />
    </div>
    <div className="col-span-2 mr-2 rounded-md bg-white p-4 dark:bg-darkContentBg">
      <LoadingSection section="currentComment" />
    </div>
  </div>
);

export default function Loading() {
  return (
    <>
      <div className="z-10 w-full border-t-2 border-headingBg bg-white dark:border-[#212121]">
        <div className="flex w-full justify-between bg-white px-5 py-4 dark:bg-darkContentBg">
          <div className="flex flex-row items-center">
            <div className="border-r-2 border-solid border-headingBg pr-5 dark:border-darkBorderSecondary">
              <div className="flex items-center justify-center whitespace-nowrap">
                <Text text={`${LABELS.reports.hospital.long_name}: `} type="secondary" />
                <div className="ml-1 inline-block h-5 w-[160px] animate-pulse bg-slate-200 dark:bg-slate-700" />
              </div>
            </div>
            <div className="border-r-2 border-solid border-headingBg px-5 dark:border-darkBorderSecondary">
              <div className="flex items-center justify-center whitespace-nowrap">
                <Text text={`${LABELS.purchases.gpo.long_name}: `} type="secondary" />
                <div className="ml-1 inline-block h-5 w-[100px] animate-pulse bg-slate-200 dark:bg-slate-700" />
              </div>
            </div>
            <div className="border-r-2 border-solid border-headingBg px-5 dark:border-darkBorderSecondary">
              <div className="flex items-center justify-center whitespace-nowrap">
                <Text text={`${LABELS.purchases.wholesaler.long_name}: `} type="secondary" />
                <div className="ml-1 inline-block h-5 w-[100px] animate-pulse bg-slate-200 dark:bg-slate-700" />
              </div>
            </div>
            <div className="px-5 ">
              <div className="flex items-center justify-center whitespace-nowrap">
                <Text text={`${LABELS.reports.review_date.long_name}: `} type="secondary" />
                <div className="ml-1 inline-block h-5 w-[280px] animate-pulse bg-slate-200 dark:bg-slate-700" />
              </div>
            </div>
          </div>

          <div className="absolute right-4 top-4 flex items-center justify-center rounded bg-white px-4 py-2 dark:bg-darkContentBg">
            <div className="ml-1 inline-block h-6 w-[350px] animate-pulse bg-slate-200 dark:bg-slate-700" />
          </div>
        </div>
      </div>

      {Array(3)
        .fill(null)
        .map((_, i) => (
          <ItemLoading key={`item-loader-${i + 1}`} />
        ))}
    </>
  );
}
