'use client';

import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { toast } from 'react-toastify';

import Error from '@/app/error';
import { ItemLoading } from '@/app/loading';
import CCRHeading from '@/components/CCRHeading';
import PurchaseItemComponent from '@/components/PurchaseItem';
import { Purchases, Reports, Statuses } from '@/services/api';
import { isPastDate } from '@/utils/date';
import { LABELS } from '@/utils/label';
import { saveSession } from '@/utils/sessionStorage';
import useWindowDimensions from '@/utils/windowDimensions';

import Button from '../common/Button';
import Checkbox from '../common/Checkbox';
import Input from '../common/Input';
import Text from '../common/Text';
import ConversionRecommendation from '../ConversionItem';
import LoadMore from '../LoadMore';
import PharmacyResponseMenu from '../PharmacyResponseMenu';
import { MIN_SCREEN_WIDTH, WHOLESALER_TO_UPDATE } from './constants';
import TotalPotentialSavings from './TotalPotentialSavings';
import type { IConversion, IPurchase } from './types';

const DynamicBestConversionItemsModal = dynamic(() => import('../BestConversionItemsModal'));
const DynamicCommentModal = dynamic(() => import('../CommentModal'));
interface IProps {
  code: string;
}

const CCRWrapper = ({ code }: IProps) => {
  const { ref, inView } = useInView();

  const { data: reports } = useQuery({
    queryKey: ['reports'],
    queryFn: async () => Reports.get(code),
  });

  const { data: totalCount } = useQuery({
    queryKey: ['totalCount'],
    queryFn: async () => Purchases.total(code),
  });

  const { data: statuses } = useQuery({
    queryKey: ['statuses'],
    queryFn: async () => Statuses.get(code),
  });

  // getLocalStorage('GeneralSettings')?.itemPerPage ||
  const [pageSize] = useState(10);
  const [totalRetrieveRecords, setTotalRetrieveRecords] = useState(10);
  const [showModalComments, setShowModalComments] = useState<boolean>(false);
  const [isABC, setIsABC] = useState<boolean>(false);

  const [showModalConversion, setShowModalConversion] = useState<boolean>(false);
  const [latestComments, setLatestComments] = useState<any>({});

  const [selectedPurchasedItem, setSelectedPurchasedItem] = useState<IPurchase | any>({});
  const [selectedConversionItem, setSelectedConversionItem] = useState<IConversion | any>({});

  const [isToastMinScreenOpened, setIsToastMinScreenOpened] = useState<boolean>(false);
  const [isResponseDateEnded, setIsResponseDateEnded] = useState<boolean>(true);

  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [filterOptions, setFilterOptions] = useState<{
    purchase: boolean;
    conversion: boolean;
    searchText: string;
    cb?: (params?: any) => void;
  }>({
    purchase: true,
    conversion: true,
    searchText: '',
  });

  const [showGPO, setShowGPO] = useState<boolean>(false);
  const [showPHS, setShowPHS] = useState<boolean>(false);
  const [showWAC, setShowWAC] = useState<boolean>(false);

  const {
    isLoading,
    isRefetching,
    data: purchases,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
  } = useInfiniteQuery(
    ['purchases'],
    async ({ pageParam = 1 }) => {
      const filter = {
        purchase: filterOptions.purchase ? filterOptions.searchText : '',
        conversion: filterOptions.conversion ? filterOptions.searchText : '',
      };
      const res = await Purchases.get(code, pageParam, pageSize, filter);
      return res.data;
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = lastPage?.length === pageSize ? (allPages?.length || 0) + 1 : undefined;
        return nextPage;
      },
    }
  );

  const windowSize = useWindowDimensions();

  useEffect(() => {
    if (windowSize.width < MIN_SCREEN_WIDTH && !isToastMinScreenOpened) {
      toast.warn('Please view this page in a larger screen for a better viewing experience.', {
        position: 'top-center',
        autoClose: false,
        onOpen: () => setIsToastMinScreenOpened(true),
      });
    }
  }, [windowSize]);

  useEffect(() => {
    saveSession('hash_code', code);
  }, []);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    if (purchases?.pages && !purchases?.pages?.[0].error && purchases?.pages?.[0].message !== 'report not found') {
      let count: number = 0;
      (purchases?.pages || []).map((p: IPurchase[]) => {
        if (Array.isArray(p)) {
          for (const obj of p) count++;
        }
      });
      setTotalRetrieveRecords(count);
      const isReportABC = reports?.data.facility?.wholesaler === WHOLESALER_TO_UPDATE;
      setIsABC(isReportABC);

      setShowGPO(reports?.data?.show_gpo);
      setShowPHS(reports?.data?.show_phs);
      setShowWAC(reports?.data?.show_wac);
    }
  }, [purchases?.pages]);

  useEffect(() => {
    setIsResponseDateEnded(reports?.data?.response_date ? isPastDate(reports?.data?.response_date) : false);
  }, [reports?.data?.response_date]);

  useEffect(() => {
    if (filterOptions.cb) {
      filterOptions.cb();
    }
  }, [filterOptions]);

  if (!isLoading && purchases?.pages?.[0].message === 'report not found') {
    return <Error errorType="not_found" />;
  }

  // Validate hashcode
  if (reports.error) {
    return <Error errorType="general" />;
  }

  const onDropdownSelect = (key: string, checked: boolean) => {
    setFilterOptions((prevState) => ({
      ...prevState,
      [key]: checked,
    }));
  };

  const onSubmitFilter = () => {
    setIsFilterApplied(true);
    refetch();
  };

  const onClearFilter = () => {
    setIsFilterApplied(false);
    setFilterOptions({
      purchase: true,
      conversion: true,
      searchText: '',
      cb: () => {
        refetch();
        setFilterOptions((prevState) => ({
          ...prevState,
          cb: undefined,
        }));
      },
    });
  };

  const hasFilter = () => {
    return filterOptions.searchText !== '' && (filterOptions.purchase || filterOptions.conversion);
  };

  const filterButtonDisabled = () => {
    return isRefetching || !hasFilter();
  };

  return (
    <>
      {/* Heading */}
      {reports?.data && (
        <CCRHeading
          hospital={reports?.data.facility?.name}
          accounts={reports?.data.facility?.accounts || []}
          gpo={reports?.data.facility?.gpo}
          wholesaler={reports?.data.facility?.wholesaler}
          startDate={reports?.data.period_start_date}
          endDate={reports?.data.period_end_date}
          responseDate={reports?.data.response_date}
          code={code}
          purchasesCount={purchases?.pages?.[0].length}
        />
      )}

      <div className="flex justify-between bg-[#d1d1d1] px-10 py-2 dark:bg-[#252525]">
        <Text text={LABELS.reports.propriety.long_name} type="secondary" />
      </div>

      <div className="flex items-center justify-between pl-10 pr-14">
        <div className="my-2 flex items-center gap-4">
          <div className="w-[400px] 2xl:w-[500px]">
            <Input
              type="text"
              label={LABELS.reports.filter.search.long_name}
              width={440}
              placeholder={LABELS.reports.filter.search.placeholder.long_name}
              value={filterOptions.searchText}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && !filterButtonDisabled()) {
                  onSubmitFilter();
                }
              }}
              onChange={(e) => {
                setFilterOptions((prevState) => ({
                  ...prevState,
                  searchText: e.target.value,
                }));
              }}
            />
          </div>
          <div className="flex items-end justify-end gap-4">
            <Checkbox
              id="filter-option-purchase"
              label={LABELS.reports.filter.search.dropdown.selection_purchased.long_name}
              labelClass="!py-3"
              onChange={(event) => onDropdownSelect('purchase', event.target.checked)}
              isChecked={filterOptions.purchase}
              checkboxPosition="left"
            />
            <Checkbox
              id="filter-option-conversion"
              label={LABELS.reports.filter.search.dropdown.selection_conversion.long_name}
              labelClass="!py-3"
              onChange={(event) => onDropdownSelect('conversion', event.target.checked)}
              isChecked={filterOptions.conversion}
              checkboxPosition="left"
            />
          </div>
          <div className="flex">
            <Button
              label="Submit"
              disabled={filterButtonDisabled()}
              btnClass={`${
                filterButtonDisabled() ? 'opacity-60 cursor-not-allowed' : ''
              } bg-[#ED1C24] text-white py-2 self-end font-normal px-4`}
              onClick={() => onSubmitFilter()}
            />
            {isFilterApplied && (
              <Button
                label="Clear Filter"
                disabled={isRefetching}
                btnClass="bg-transparent text-blue py-2 self-end font-medium"
                onClick={() => onClearFilter()}
              />
            )}
          </div>
        </div>
        <div className="flex">
          <Text text={`${LABELS.reports.total_records.long_name}: `} type="secondary" />
          <span className="ml-1">
            <Text text={` ${totalRetrieveRecords} of ${totalCount.data}`} weight="bold" />
          </span>
        </div>
      </div>

      <div className="overflow-y-auto">
        <table className="w-full flex-auto overflow-y-auto">
          <thead>
            <tr>
              <th className="sticky top-[-2px] z-[1]">
                <div className="grid grid-cols-12 px-10 pb-0">
                  <div className="col-span-5 flex items-center rounded-l-md bg-blue p-2 pl-6 text-left text-lg font-semibold text-white">
                    {LABELS.purchases.column_header}
                  </div>
                  <div className="col-span-5 bg-green text-left text-lg font-semibold text-white">
                    <div className="ml-6 flex h-full items-center justify-between">
                      <div className="my-2">{LABELS.conversions.column_header}</div>
                      <TotalPotentialSavings
                        amount={reports?.data?.reportTotalPotentialSavings?.total_potential_savings}
                      />
                    </div>
                  </div>
                  <div className="col-span-2 flex items-center rounded-r-md bg-red p-2 pl-6 text-left text-lg font-semibold text-white">
                    {LABELS.pharmacy_response.column_header}
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isRefetching ? (
              <>
                {Array(3)
                  .fill(null)
                  .map((_, i) => (
                    <ItemLoading key={`item-loader-${i + 4}`} />
                  ))}
              </>
            ) : (
              <>
                {!!purchases?.pages?.[0]?.length && (
                  <>
                    {(purchases?.pages || []).map((page: IPurchase[]) =>
                      (page || []).map((purchaseItem: IPurchase) => (
                        <tr ref={ref} key={`Purchase-${purchaseItem.id}`}>
                          <td>
                            <div className="mx-10 mt-2 grid grid-cols-12 rounded">
                              <div
                                className="col-span-5 mr-2 rounded-md bg-white p-4 dark:bg-darkContentBg"
                                id={`Purchase-${purchaseItem.id}`}
                              >
                                <PurchaseItemComponent
                                  purchaseItem={purchaseItem}
                                  isABC={isABC}
                                  showGPO={showGPO}
                                  showPHS={showPHS}
                                  showWAC={showWAC}
                                />
                              </div>

                              {purchaseItem?.topConversion && (
                                <>
                                  <div
                                    className="col-span-5 mr-2 flex flex-col justify-between rounded-md bg-white p-4 dark:bg-darkContentBg"
                                    id={`Conversion-${purchaseItem.id}`}
                                  >
                                    <ConversionRecommendation
                                      item={purchaseItem.topConversion}
                                      isABC={isABC}
                                      showGPO={showGPO}
                                      showPHS={showPHS}
                                      showWAC={showWAC}
                                    />
                                  </div>

                                  <div
                                    className="col-span-2 flex flex-col rounded-md bg-white p-4 dark:bg-darkContentBg"
                                    id={`Response-${purchaseItem.id}`}
                                  >
                                    <PharmacyResponseMenu
                                      setShowModalComments={setShowModalComments}
                                      setShowModalConversion={setShowModalConversion}
                                      comment={
                                        latestComments[purchaseItem?.topConversion?.id || 0] ||
                                        purchaseItem?.topConversion?.latestComment
                                      }
                                      setLatestComments={setLatestComments}
                                      statuses={statuses.data}
                                      // Get Purchased Item obj
                                      selectedPurchasedItem={purchaseItem}
                                      setSelectedPurchasedItem={setSelectedPurchasedItem}
                                      // Get Conversion Item obj
                                      selectedConversionItem={purchaseItem?.topConversion}
                                      setSelectedConversionItem={setSelectedConversionItem}
                                      isResponseDateEnded={isResponseDateEnded}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                    <tr>
                      <td>
                        <LoadMore {...{ isFetchingNextPage: !!isFetchingNextPage }} />
                      </td>
                    </tr>
                  </>
                )}
                {purchases?.pages?.[0]?.length === 0 && (
                  <tr>
                    <td>
                      <Error errorType={hasFilter() ? 'no_filter_result' : 'no_items'} />
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>

      {/* Comments */}
      {showModalComments && (
        <DynamicCommentModal
          {...{
            showModal: showModalComments,
            setShowModal: setShowModalComments,
            selectedPurchasedItem,
            selectedConversionItem,
          }}
        />
      )}

      {/* Best Conversion Items */}
      {showModalConversion && (
        <DynamicBestConversionItemsModal
          {...{
            isABC,
            showGPO,
            showPHS,
            showWAC,
            showModal: showModalConversion,
            setShowModal: setShowModalConversion,
            isResponseDateEnded,
            statuses: statuses.data,
            selectedPurchasedItem,
            selectedConversionItem,
          }}
        />
      )}
    </>
  );
};

export default CCRWrapper;
