import type { SVGProps } from 'react';

const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={16}
    fill="none"
    {...props}
    className="fill-icon dark:fill-darkIconSecondary"
  >
    <path d="M5.5 16c-1.52 0-2.817-.523-3.89-1.57C.537 13.377 0 12.093 0 10.58c0-1.3.39-2.46 1.17-3.48a5.188 5.188 0 0 1 3.08-1.95 7.042 7.042 0 0 1 2.14-3.4A6.869 6.869 0 0 1 10 .08v8.07L8.4 6.6 7 8l4 4 4-4-1.4-1.4L12 8.15V.08c1.72.233 3.15 1.003 4.29 2.31C17.43 3.697 18 5.233 18 7c1.153.133 2.107.633 2.86 1.5a4.367 4.367 0 0 1 1.14 3c0 1.253-.437 2.317-1.31 3.19-.873.873-1.937 1.31-3.19 1.31h-12Z" />
  </svg>
);
export default DownloadIcon;
