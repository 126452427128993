const LoadingItem = () => (
  <div className="flex animate-pulse space-x-4">
    <div className="flex-1 space-y-6">
      <div className="h-5 rounded bg-slate-200 dark:bg-slate-700" />
      <div className="space-y-3 py-1">
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-1 h-3 rounded bg-slate-200 dark:bg-slate-700" />
          <div className="col-span-1" />
          <div className="col-span-1 h-3 rounded bg-slate-200 dark:bg-slate-700" />
        </div>
        <div className="grid grid-cols-8 gap-4">
          <div className="col-span-3 h-3 rounded bg-slate-200 dark:bg-slate-700" />
          <div className="col-span-1" />
          <div className="col-span-3 h-3 rounded bg-slate-200 dark:bg-slate-700" />
        </div>
      </div>
    </div>
  </div>
);
export default LoadingItem;
