'use client';

import classnames from 'classnames';
import type { ChangeEvent, KeyboardEvent } from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import type { IStatuses } from '@/interfaces/statuses';
import { Comments } from '@/services/api';
import { LABELS } from '@/utils/label';
import logger from '@/utils/logger';
import { getSession } from '@/utils/sessionStorage';

import type { IComment, IConversion, IPurchase } from '../CCR/types';
import IconWithLabel from '../common/IconWithLabel';
import Text from '../common/Text';
import Tooltip from '../common/tooltip';

interface IProps {
  statuses: IStatuses[];
  comment?: IComment;
  purchasedItem?: string;
  setShowModalComments: (val: boolean) => void;
  showMoreOptions?: boolean;
  setShowModalConversion?: (val: boolean) => void;
  setLatestComments?: Function;
  selectedPurchasedItem?: IPurchase | any;
  setSelectedPurchasedItem?: (val: IPurchase) => void;
  selectedConversionItem?: IConversion | any;
  setSelectedConversionItem?: (val: IConversion) => void;
  isResponseDateEnded?: boolean;
}

interface LatestCommentsProps {
  [key: number]: IComment;
}

const PharmacyResponseMenu = ({
  statuses,
  comment,
  setShowModalComments,
  showMoreOptions = true,
  setShowModalConversion,
  setLatestComments = () => {},
  selectedPurchasedItem,
  setSelectedPurchasedItem,
  selectedConversionItem,
  setSelectedConversionItem,
  isResponseDateEnded,
}: IProps) => {
  const [isCommentSaving, setIsCommentSaving] = useState(false);
  const [newComment, setNewComment] = useState(comment?.message || '');
  const [newStatus, setNewStatus] = useState(comment?.status || '');
  const [isCarryForward, setIsCarryForward] = useState<boolean>(comment?.is_carry_forward ?? false);

  useEffect(() => {
    setLatestComments((prevValues: LatestCommentsProps) => ({ ...prevValues, [selectedConversionItem.id]: comment }));
  }, []);

  const handleSubmitComment = async (updatedStatus?: string) => {
    const status = updatedStatus !== undefined ? updatedStatus : newStatus;
    if (
      // allow if comment exists
      (newComment ||
        // or allow empty comment if status is not HP (HP requires comment)
        (!newComment && status !== 'HP')) &&
      // check if any field was updated before saving again
      (status !== (comment?.status || '') || newComment !== (comment?.message || ''))
    ) {
      try {
        setIsCommentSaving(true);
        const { data }: { data: IComment } = await toast.promise(
          // Make the API request to post the comment
          new Promise((resolve, reject) =>
            // eslint-disable-next-line no-promise-executor-return
            Comments.post(selectedConversionItem.id, {
              author: 'MF',
              status: status || '',
              message: newComment || '',
            }).then((response) => (response?.data?.id ? resolve(response) : reject(response)))
          ),
          {
            pending: LABELS.pharmacy_response.save_pending.long_name,
            success: LABELS.pharmacy_response.save_success.long_name,
            error: LABELS.pharmacy_response.save_failed.long_name,
          },
          {
            position: 'top-right',
          }
        );
        setLatestComments((prevValues: LatestCommentsProps) => ({
          ...prevValues,
          [selectedConversionItem.id]: { status: data?.status, message: data?.message },
        }));
      } catch (error: any) {
        const hashCode = getSession('hash_code');
        logger.logEvent(
          'error',
          'components/PharmacyResponseMenu',
          `Failed to save comment for Conversion ID ${selectedConversionItem.id}`,
          error.message,
          error,
          hashCode
        );
      } finally {
        setIsCommentSaving(false);
      }
    }
  };

  const handleConfirmCarryForwardComment = async () => {
    try {
      setIsCommentSaving(true);
      const { data }: { data: IComment } = await toast.promise(
        // Make the API request to post the comment
        new Promise((resolve, reject) =>
          // eslint-disable-next-line no-promise-executor-return
          Comments.put(comment?.id || 0, { is_carry_forward: false }).then((response) =>
            response?.data?.id ? resolve(response) : reject(response)
          )
        ),
        {
          pending: LABELS.pharmacy_response.save_pending.long_name,
          success: LABELS.pharmacy_response.save_success.long_name,
          error: LABELS.pharmacy_response.save_failed.long_name,
        },
        {
          position: 'top-right',
        }
      );
      setIsCarryForward(!!data.is_carry_forward);
    } catch (error: any) {
      const hashCode = getSession('hash_code');
      logger.logEvent(
        'error',
        'components/PharmacyResponseMenu',
        `Failed to confirm carry-forward comment for Conversion ID ${selectedConversionItem.id}`,
        error.message,
        error,
        hashCode
      );
    } finally {
      setIsCommentSaving(false);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      handleSubmitComment();
    }
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const updatedStatus = e.target.value;
    setNewStatus(updatedStatus);
    // save if status is not HP
    if (
      updatedStatus !== 'HP' ||
      // or save if status is HP and comment is not empty
      (updatedStatus === 'HP' && newComment)
    ) {
      handleSubmitComment(updatedStatus);
    }
  };

  const commentWrapper = classnames(
    'flex h-[inherit] flex-col rounded-md border border-borderTertiary dark:border-darkBorderPrimary',
    {
      'h-3/4': showMoreOptions,
      'h-5/6': !showMoreOptions,
    }
  );
  const iconCommentWrapper = classnames({
    'mt-2': showMoreOptions,
    'mt-3': !showMoreOptions,
  });
  const selectWrapper = classnames(`border-b border-borderTertiary py-2 pr-2`, {
    'border-0 bg-transparent dark:bg-textPrimary': !comment?.status,
    'border border-green bg-lightGreen dark:bg-darkLightGreen': comment?.status && !isCarryForward,
    'border border-yellow bg-lightYellow dark:bg-darkYellow': comment?.status && isCarryForward,
  });
  const selectClass = classnames(
    `h-[24px] w-full rounded-md py-0 pl-2 pr-4 text-gray-500
  invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 disabled:border-slate-200
  disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none dark:text-white
  dark:disabled:border-slate-900 dark:disabled:bg-slate-800 dark:disabled:text-slate-500 dark:disabled:shadow-none sm:text-sm
  `,
    {
      'bg-transparent': !comment?.status,
      'bg-lightGreen dark:bg-darkLightGreen': comment?.status && !isCarryForward,
      'bg-lightYellow dark:bg-darkYellow': comment?.status && isCarryForward,
    }
  );
  const textAreaWrapper = classnames(
    `peer w-full flex-1 resize-none rounded-md bg-transparent py-2 pl-2 pr-7 text-gray-500 invalid:border
  invalid:border-pink-500 invalid:text-pink-600 focus:ring-2 focus:invalid:border-pink-500
  focus:invalid:ring-pink-500 disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500
  disabled:shadow-none dark:text-white dark:disabled:border-slate-900  dark:disabled:bg-slate-800
  dark:disabled:text-slate-500 dark:disabled:shadow-none sm:text-sm`
  );

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="flex h-full flex-col">
        <div className={commentWrapper}>
          <div className="flex items-center justify-between rounded-t-md border-b border-borderTertiary bg-even p-3 dark:border-darkBorderPrimary dark:bg-darkBg">
            <Text text={LABELS.pharmacy_response.current_comment.long_name} size="sm" weight="semibold" />
            {isCarryForward && (
              <Tooltip
                content="Status and comment carried over from previous month."
                customClass="w-[270px]"
                placement="left"
              >
                <span className="mt-2 flex items-center text-4xl leading-[16px] text-danger"> * </span>
              </Tooltip>
            )}
          </div>

          <form className="flex h-full flex-col">
            <div className={selectWrapper}>
              <select
                disabled={isCommentSaving || isResponseDateEnded}
                value={newStatus}
                onChange={handleSelectChange}
                className={selectClass}
                {...(isCarryForward ? { onClick: handleConfirmCarryForwardComment } : {})}
              >
                <option className="bg-white dark:bg-darkContentBg" value="">
                  {LABELS.pharmacy_response.choose_status.long_name}
                </option>
                {statuses.map(({ name, description }) => (
                  <option
                    className="bg-white dark:bg-darkContentBg"
                    key={name}
                    value={name}
                  >{`${name} - ${description}`}</option>
                ))}
              </select>
            </div>
            <div className="flex flex-1 flex-col rounded-b-md bg-white p-3 dark:bg-textPrimary">
              <textarea
                required={newStatus === 'HP'}
                disabled={isCommentSaving || isResponseDateEnded}
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                onBlur={() => handleSubmitComment()}
                onKeyDown={handleKeyDown}
                placeholder={LABELS.pharmacy_response.comment_placeholder.long_name}
                className={textAreaWrapper}
                {...(isCarryForward ? { onClick: handleConfirmCarryForwardComment } : {})}
              />
              <p className="hidden text-sm text-pink-500 peer-invalid:block">
                {LABELS.pharmacy_response.comment_required.long_name}
              </p>
              {isCarryForward && (
                <div className="text-xs text-danger">{LABELS.pharmacy_response.carried_forward.long_name}</div>
              )}
            </div>
          </form>
        </div>
      </div>

      <div>
        <div className={iconCommentWrapper}>
          <IconWithLabel
            label={LABELS.pharmacy_response.comment_history.long_name}
            icon="comments"
            contentPosition="left"
            handleClick={() => {
              setShowModalComments(true);
              setSelectedConversionItem?.(selectedConversionItem);
            }}
            hasBg
            isWithArrow
          />
        </div>

        {showMoreOptions && (
          <div className="mt-2">
            <IconWithLabel
              label={LABELS.pharmacy_response.alternate_items.long_name}
              icon="moreoptions"
              contentPosition="left"
              handleClick={() => {
                setShowModalConversion?.(true);
                setSelectedPurchasedItem?.(selectedPurchasedItem);
                setSelectedConversionItem?.(selectedConversionItem);
              }}
              hasBg
              isWithArrow
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PharmacyResponseMenu;
