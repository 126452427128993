'use client';

import classnames from 'classnames';
import type { ReactNode } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

export interface TooltipProps {
  content: string;
  children?: ReactNode;
  mouseEnterDelay?: number;
  customClass?: string;
  customDivClass?: string;
  isInlineBlock?: boolean;
  enableHover?: boolean;
}

interface IProps {
  visible: boolean;
  tipWrapper: string;
  tooltipClass: string;
  content: React.ReactNode;
  targetRef: any;
}

const PortalTooltip = ({ visible, tipWrapper, tooltipClass, content, targetRef }: IProps) => {
  const tooltipRef = useRef<any>(null);
  const [style, setStyle] = useState({});

  useEffect(() => {
    if (targetRef.current && tooltipRef.current && visible) {
      const rect = targetRef.current.getBoundingClientRect();
      const tooltipReact = tooltipRef.current.getBoundingClientRect();
      setStyle({
        position: 'absolute',
        top: rect.top + window.scrollY - tooltipReact.height - 10,
        left: rect.left + window.scrollX + rect.width / 2,
        transform: 'translateX(-50%)',
      });
    }
  }, [targetRef, visible, tooltipRef]);

  if (!visible || !document) return null;

  return ReactDOM.createPortal(
    <div ref={tooltipRef} className={`${tipWrapper} ${tooltipClass}`} style={style}>
      <span
        className="relative z-10 text-sm font-normal leading-none text-textPrimary dark:text-white"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: content || '' }}
      />
    </div>,
    document?.body
  );
};

const TooltipPortal = ({
  children,
  content,
  mouseEnterDelay = 100,
  customClass = '',
  customDivClass = '',
  isInlineBlock = true,
  enableHover = true,
}: TooltipProps) => {
  let timeout: ReturnType<typeof setTimeout>;
  let tooltipClass: string = 'w-[260px]';

  const targetRef = useRef(null);
  const [active, setActive] = useState<boolean>(false);

  const showTip = () => {
    if (enableHover) {
      timeout = setTimeout(() => {
        setActive(true);
      }, mouseEnterDelay);
    }
  };

  const hideTip = () => {
    if (enableHover) {
      clearInterval(timeout);
      setActive(false);
    }
  };

  if (customClass) {
    tooltipClass = customClass;
  }

  const tipWrapper = classnames(
    `absolute z-50 rounded-md bg-white p-3 before:pointer-events-none before:absolute before:left-2/4 before:ml-[calc(6px*-1)] before:h-0 before:w-0 before:border-[6px] before:border-transparent dark:bg-black`,
    'mb-[5px] -translate-x-2/4 shadow-tooltipTop before:top-full before:border-t-white'
  );

  const divWrapper = classnames('relative', customDivClass, { 'inline-block': isInlineBlock });

  return (
    <div ref={targetRef} className={divWrapper} onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      <PortalTooltip {...{ visible: active, tipWrapper, tooltipClass, content, targetRef }} />
    </div>
  );
};

export default TooltipPortal;
