import logger from './logger';
import { getSession } from './sessionStorage';

/**
 * Next.js uses server-side rendering so localStorage is not defined
 * To avoid error throwing from localStorage, there should be a check first
 * if typeof window !== 'undefined', to use localStorage for client side only
 */
export const getLocalStorage = (key: string) => {
  if (typeof window !== 'undefined') {
    try {
      const serializedState = localStorage.getItem(`${key}`);
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (error: any) {
      const hashCode = getSession('hash_code');
      logger.logEvent(
        'error',
        'utils/localStorage',
        `Failed to get localStorage item for ${key}`,
        error.message,
        error,
        hashCode
      );
      return undefined;
    }
  }
};

export const saveLocalStorage = (key: string, value: any) => {
  if (typeof window !== 'undefined') {
    try {
      const serializedState = JSON.stringify(value);
      localStorage.setItem(`${key}`, serializedState);
    } catch (error: any) {
      const hashCode = getSession('hash_code');
      logger.logEvent(
        'error',
        'utils/localStorage',
        `Failed to set localStorage item for ${key}`,
        error.message,
        error,
        hashCode
      );
      return undefined;
    }
  }
};

export const removeLocalStorage = (key: string) => {
  if (typeof window !== 'undefined') {
    return localStorage.removeItem(`${key}`);
  }
};

// for adjExtCost field, default should be false and for other fields default is true
export const getSettings = (category: string, key: string) =>
  getLocalStorage(category)?.[key] ?? key !== 'adjExtCostV2';
