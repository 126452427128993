import type { SVGProps } from 'react';

const PrinterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={18}
    fill="none"
    {...props}
    className="fill-icon dark:fill-darkIconSecondary"
  >
    <path d="M16 0H4v4h12m1 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm-3 7H6v-5h8m3-6H3a3 3 0 0 0-3 3v6h4v4h12v-4h4V8a3 3 0 0 0-3-3Z" />
  </svg>
);
export default PrinterIcon;
