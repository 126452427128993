import millify from 'millify';

export const isValidNumber = (num: any) => {
  const parsed = parseFloat(num);
  return {
    isValid: !Number.isNaN(parsed),
    value: Number.isNaN(parsed) ? num : parsed,
  };
};

export const formatToDecimal = (num: any, decimalPlaces = 2) => {
  const { isValid, value } = isValidNumber(num);
  return isValid ? value.toFixed(decimalPlaces) : '';
};

export const formatToPercent = (num: any, decimalPlaces: number = 2, isSpaced: boolean = false) => {
  const { isValid, value } = isValidNumber(num);
  return isValid ? `${(value * 100).toFixed(decimalPlaces)}${isSpaced ? ' ' : ''}%` : '';
};

export const formatToCurrency = (num: number | string | null | undefined, decimalPlaces = 2) => {
  const { isValid, value } = isValidNumber(num);

  if (!isValid) return '';

  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(value);
};

export const formatToDecimalUS = (num: number | string | null | undefined, decimalPlaces = 2, isNAon = false) => {
  const { isValid, value } = isValidNumber(num);

  if (!isValid) return isNAon ? 'N/A' : '';

  return Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(value);
};

export const toQueryString = (params: { [key: string]: any }) =>
  Object.keys(params)
    .filter((key) => params[key] !== undefined)
    .map((key, index) => `${index === 0 ? '?' : ''}${key}=${params[key]}`)
    .join('&');

export const paginate = ({ data = [], page = 1, pageSize = 10 }: { data: any[]; page?: number; pageSize?: number }) => {
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  return data.slice(startIndex, endIndex);
};

export const formatNDC = (ndc: string) => {
  const withLeadingZeros = ndc.toString().padStart(11, '0');

  const formatted = withLeadingZeros.replace(/^(\d{5})(\d{4})(\d{2})$/, '$1-$2-$3');
  return formatted;
};

export const safeDecodeURIComponent = (str: string) => {
  try {
    return decodeURIComponent(str);
  } catch (e) {
    // Fix any invalid percent-encoded sequences
    return str.replace(/%(?![0-9A-Fa-f]{2})/g, '%25');
  }
};

export const escapeSqlSpecialChars = (str: string) => {
  return str.replace(/\[/g, '[[]').replace(/%25/g, '[%]').replace(/_/g, '[_]');
};

export const formatToShortened = (num: number | string | null | undefined, isNAon = false) => {
  const { isValid, value } = isValidNumber(num);

  if (!isValid) return isNAon ? 'N/A' : '';

  if (value > 9999) return `$${millify(value)}`;

  return formatToCurrency(value);
};
