'use client';

import classnames from 'classnames';

import { DownloadIcon, OpenIcon, PrinterIcon, ReplyIcon, SettingsIcon } from '../icons';
import FilterIcon from '../icons/FilterIcon';

interface IProps {
  label: string;
  icon: string;
  handleClick?: () => void;
  contentPosition?: 'left' | 'center' | 'right';
  iconPosition?: 'left' | 'center' | 'right';
  hasBg?: boolean;
  disabled?: boolean;
  isWithArrow?: boolean;
  className?: string;
}

const IconWithLabel = ({
  label,
  icon,
  handleClick,
  contentPosition = 'center',
  iconPosition = 'center',
  hasBg = false,
  disabled = false,
  isWithArrow = false,
  className = '',
}: IProps) => {
  const renderIcon = (icon: string) => {
    switch (icon) {
      case 'print':
        return <PrinterIcon />;
      case 'download':
        return <DownloadIcon />;
      case 'settings':
        return <SettingsIcon />;
      case 'comments':
        return <ReplyIcon />;
      case 'moreoptions':
        return <OpenIcon />;
      case 'filter':
        return <FilterIcon />;
    }
  };
  // `${contentPosition}`,
  const iconWrapperClass = classnames('icon-wrapper', {
    'flex-row-reverse': iconPosition === 'right',
    'justify-center': contentPosition === 'center',
    'justify-start': contentPosition === 'left',
    'justify-end': contentPosition === 'right',
  });
  const divWrapperClass = classnames('flex justify-between rounded', {
    'disabled-state': disabled,
    'border border-borderTertiary bg-even dark:border-darkBorderPrimary dark:bg-darkBg': hasBg,
    'hover:bg-costBg dark:border dark:border-transparent hover:dark:border-darkBorderPrimary dark:hover:bg-transparent':
      !hasBg,
  });

  const iconLabelClass = classnames('ml-3 text-base text-sm text-textPrimary dark:text-darkTextPrimary', {
    'font-semibold': hasBg,
    'dark:text-textSecondary': !hasBg,
    'ml-0 mr-3': iconPosition === 'right',
  });

  return (
    <div onClick={handleClick} className={`${divWrapperClass} ${className}`}>
      <div className={iconWrapperClass}>
        {renderIcon(icon)}
        <span className={iconLabelClass}>{label}</span>
      </div>
      {isWithArrow && <div className="mr-3 py-1">&gt;</div>}
    </div>
  );
};

export default IconWithLabel;
