import { formatToCurrency } from '@/utils/common';
import { LABELS } from '@/utils/label';

const TotalPotentialSavings = ({ amount }: { amount: number }) => (
  <div className="flex h-full items-center justify-center gap-2 bg-[#08692F] px-4 py-2 text-white">
    <span className="text-sm font-semibold">{LABELS.reports.total_potential_savings.long_name}:</span>
    <span className="text-base font-semibold">{formatToCurrency(amount)}</span>
  </div>
);

export default TotalPotentialSavings;
