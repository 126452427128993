import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';
import { toast } from 'react-toastify';

import type { IComment, IConversion } from '@/components/CCR/types';
import type { IReportFilter } from '@/interfaces/filters';
import { toQueryString } from '@/utils/common';
import { LABELS } from '@/utils/label';
import { getSession } from '@/utils/sessionStorage';

const API_ROOT = process.env.NEXT_PUBLIC_API_ENDPOINT || process.env.API_ENDPOINT;

const NOT_FOUND_ERROR_MESSAGES = ['Not Found', 'report not found'];

const handleErrors = (error: AxiosError, errorMessage?: string) => {
  const data: { message?: string } = error?.response?.data || { message: '' };
  const status = error?.response?.status;
  if (errorMessage && typeof window !== 'undefined' && !NOT_FOUND_ERROR_MESSAGES.includes(data?.message || '')) {
    toast.warn(errorMessage, { position: 'top-center' });
  }
  return {
    status,
    data,
    error: true,
  };
};

const handleResponse = (res: AxiosResponse) => {
  return res?.data;
};

const createApi = () => {
  const api = axios.create({
    baseURL: API_ROOT,
    timeout: 1200000,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'X-Transaction-ID': getSession('X-Transaction-ID'),
    },
  });

  api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const { status } = error.response;

      return Promise.reject(error);
    }
  );

  return api;
};

const requests = {
  get: (url: string, errorMessage?: string) =>
    createApi()
      .get(`${API_ROOT}${url}`)
      .then(handleResponse)
      .catch((error) => handleErrors(error, errorMessage)),
  post: (url: string, data: any, errorMessage?: string) =>
    createApi()
      .post(`${API_ROOT}${url}`, data)
      .then(handleResponse)
      .catch((error) => handleErrors(error, errorMessage)),
  put: (url: string, data: any, errorMessage?: string) =>
    createApi()
      .put(`${API_ROOT}${url}`, data)
      .then(handleResponse)
      .catch((error) => handleErrors(error, errorMessage)),
  delete: (url: string, errorMessage?: string) =>
    createApi()
      .delete(`${API_ROOT}${url}`)
      .then(handleResponse)
      .catch((error) => handleErrors(error, errorMessage)),
};

// Reports
const Reports = {
  get: (id?: string) => requests.get(`/reports/${id}`, LABELS.common.toasts.failed.long_name('loading', 'report')),
};

// Purchases
const Purchases = {
  get: (id?: string, page?: string | number, pageSize?: string | number, filters?: IReportFilter) =>
    requests.get(
      `/reports/${id}/purchases${toQueryString({
        page,
        pageSize,
        filters: encodeURIComponent(JSON.stringify(filters)),
      })}`,
      LABELS.common.toasts.failed.long_name('loading', 'purchases')
    ),
  getAll: (id?: string) => requests.get(`/reports/${id}/purchases`),
  total: (code?: string) =>
    requests.get(
      `/reports/${code}/purchases/total-count`,
      LABELS.common.toasts.failed.long_name('loading', 'total count of purchases')
    ),
};

// Purchase
const Purchase = {
  get: (id?: string, code?: string) => {
    const hashCode = code ?? getSession('hash_code');
    return requests.get(
      `/purchases/${id}${toQueryString({ hashCode })}`,
      LABELS.common.toasts.failed.long_name('loading', 'purchase')
    );
  },
};

// Conversions
const Conversions = {
  get: (
    id?: string | number,
    page?: string | number,
    pageSize?: string | number,
    code?: string,
    abc_update?: boolean
  ) => {
    const hashCode = code ?? getSession('hash_code');
    return requests.get(
      `/purchases/${id}/conversions${toQueryString({ page, pageSize, hashCode, abc_update })}`,
      LABELS.common.toasts.failed.long_name('loading', abc_update ? 'conversions updated QTY available' : 'conversions')
    );
  },
  put: (id: string | number, data: IConversion, code?: string) => {
    const hashCode = code ?? getSession('hash_code');
    return requests.put(
      `/conversions/${id}`,
      { ...data, hashCode },
      LABELS.common.toasts.failed.long_name('updating', 'conversion')
    );
  },
};

// Comments
const Comments = {
  get: (id?: string | number, page?: string | number, pageSize?: string | number, code?: string) => {
    const hashCode = code ?? getSession('hash_code');
    return requests.get(
      `/conversions/${id}/comments${toQueryString({ page, pageSize, hashCode })}`,
      LABELS.common.toasts.failed.long_name('loading', 'comments')
    );
  },
  post: (id: string | number, data: IComment, code?: string) => {
    const hashCode = code ?? getSession('hash_code');
    return requests.post(`/conversions/${id}/comments`, { ...data, hashCode });
  },
  put: (id: string | number, data: IComment) => {
    return requests.put(`/comments/${id}`, data);
  },
  delete: (id: number | string, code?: string) => {
    const hashCode = code ?? getSession('hash_code');
    return requests.delete(
      `/comments/${id}${toQueryString({ hashCode })}`,
      LABELS.common.toasts.failed.long_name('deleting', 'comment')
    );
  },
};

// Statuses
const Statuses = {
  get: (code?: string) => {
    const hashCode = code ?? getSession('hash_code');
    return requests.get(
      `/statuses${toQueryString({ hashCode })}`,
      LABELS.common.toasts.failed.long_name('loading', 'statuses')
    );
  },
};

export { Comments, Conversions, Purchase, Purchases, Reports, Statuses };
