const LoadingCurrentComment = () => (
  <div className="flex animate-pulse space-x-4">
    <div className="flex-1 space-y-4">
      <div className="h-5 rounded bg-slate-200 dark:bg-slate-700" />
      <div className="h-5 rounded bg-slate-200 dark:bg-slate-700" />
      <div className="h-5 rounded bg-slate-200 dark:bg-slate-700" />
    </div>
  </div>
);
export default LoadingCurrentComment;
