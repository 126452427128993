export const ICONS_WIDTH: {
  [key: string]: number;
} = {
  hth: 55,
  nvp: 55,
  onestop: 79,
  pgx: 52,
  ppx: 53,
  pref: 59,
  prxo: 61,
  source: 68,
  'W < G': 69,
  'W < G/P': 81,
  'PW < G': 77,
  'PW < G/P': 89,
  recChg: 77,
};
