'use client';

import classnames from 'classnames';
import type { KeyboardEvent } from 'react';

interface IProps {
  label?: string;
  name?: string;
  value?: string | number;
  type?: 'text' | 'number' | 'email';
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  labelPosition?: 'left' | 'right' | 'center';
  layout?: 'horizontal' | 'vertical';
  width?: number;
  hint?: string;
  message?: string;
  messageState?: 'error' | 'success' | 'info' | 'warning';
  disabled?: boolean;
  // size?: 'large' | 'default' | 'small';
}

const Input = ({
  label,
  name,
  value,
  type = 'text',
  onFocus,
  onKeyDown,
  onChange,
  placeholder,
  labelPosition = 'left',
  layout = 'horizontal',
  width = 160,
  hint,
  message,
  messageState,
  disabled = false,
}: IProps) => {
  const inputWrapperComponent = classnames({
    'flex items-center justify-between gap-2': layout === 'horizontal',
    'flex flex-col': layout === 'vertical',
  });
  const labelWrapper = classnames('py-2 text-base font-medium text-gray-900 dark:text-darkTextPrimary', {
    'text-center': labelPosition === 'center',
    'text-right': labelPosition === 'right',
    'text-left': labelPosition === 'left',
  });
  const messageWrapper = classnames('mt-1 text-sm', {
    'text-danger': messageState === 'error',
    'text-textSuccess': messageState === 'success',
    'text-textWarning': messageState === 'warning',
    'text-textSecondary': messageState === 'info',
  });
  const inputWrapper = classnames(
    'focus:ring-blue-500 focus:border-blue-500 block rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:bg-[#151515] dark:text-darkTextPrimary',
    {
      'border-textSuccess text-textSuccess': messageState === 'success',
      'border-danger text-danger': messageState === 'error',
    }
  );

  return (
    <div className={inputWrapperComponent}>
      <label className={labelWrapper}>{label}</label>
      <input
        type={type}
        name={name}
        value={value}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        className={inputWrapper}
        style={{
          width,
        }}
      />
      {hint && <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">{hint}</p>}
      {message && <p className={messageWrapper}>{message}</p>}
    </div>
  );
};

export default Input;
