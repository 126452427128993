import { LABELS } from '@/utils/label';

import CheckIcon from '../icons/CheckIcon';
import Text from '../Text';

/* eslint-disable react/no-unused-prop-types */
export interface ColumnHeader {
  key: string;
  title: string;
  align?: 'left' | 'right' | 'center';
  type?: 'string' | 'number' | 'boolean';
}
/* eslint-enable react/no-unused-prop-types */

interface CellProps {
  type?: string;
  value: any;
}

interface IProps {
  keyString: string;
  columnHeaders: ColumnHeader[];
  data: any[];
}
const Table = ({ keyString, columnHeaders, data }: IProps) => {
  const renderCell = ({ type, value }: CellProps) => {
    switch (type) {
      case 'boolean':
        return value ? (
          <div className="flex items-center justify-center">
            <CheckIcon />
          </div>
        ) : (
          ''
        );
      default:
        return <Text text={value} />;
    }
  };

  return (
    <div className="max-h-[60vh] w-full overflow-auto border border-borderTertiary dark:border-textPrimary">
      <table>
        <thead className="sticky top-0 z-[100]">
          <tr>
            {columnHeaders.map(({ key, title, align = 'left' }: ColumnHeader) => (
              <th
                className="bg-borderTertiary p-3 dark:border-darkBorderPrimary dark:bg-textPrimary"
                style={{ textAlign: align }}
                key={key}
              >
                <Text text={title} weight="semibold" />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => (
            <tr key={row?.[keyString]} className={i % 2 ? 'bg-even dark:bg-darkEven' : 'bg-white dark:bg-black'}>
              {columnHeaders.map(({ key, align = 'left', type }: ColumnHeader) => (
                <td className="px-3 py-[6px]" key={key} style={{ textAlign: align }}>
                  {renderCell({ type, value: row?.[key] })}
                </td>
              ))}
            </tr>
          ))}
          {data.length === 0 && (
            <tr>
              <td colSpan={columnHeaders.length} className="text-center">
                {LABELS.common.errors.no_items.header.long_name}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
