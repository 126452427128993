import { type ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';

import Button from '../Button';
import { CloseIcon } from '../icons';

interface IProps {
  isOpen: boolean;
  title?: string;
  onClose: () => void;
  children: ReactNode;
}
const PortalModal = ({ isOpen, title, onClose, children }: IProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isOpen]);

  if (!isOpen || !document) return null;

  return ReactDOM.createPortal(
    <div className="fixed left-0 top-0 z-[99] flex h-full w-full items-center justify-center bg-black/50 ">
      <div className="relative flex flex-col rounded-md border-0 bg-white p-6 shadow-lg outline-none focus:outline-none dark:border dark:border-textPrimary dark:bg-black">
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <h3 className="text-xl font-semibold text-textPrimary dark:text-darkTextPrimary">{title}</h3>
          </div>
          <Button
            className="flex h-[48px] w-[48px] items-center justify-center rounded-md border-2 border-solid border-iconBg bg-odd dark:border-darkBorderPrimary dark:bg-transparent "
            handleClick={onClose}
          >
            <CloseIcon />
          </Button>
        </div>
        <div className="mt-8 h-full overflow-y-auto">{children}</div>
      </div>
    </div>,
    document?.body
  );
};

export default PortalModal;
