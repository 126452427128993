import { captureEvent, captureException } from '@sentry/nextjs';

/* Use for capturing runtime errors including stacktrace */
const logException = (e: any) => {
  // eslint-disable-next-line no-console
  console.error(e);
  captureException(e);
};

/* Use for tracing "any" logs including data */
const logEvent = (
  severity: 'error' | 'info',
  name: string,
  message: string,
  reason: string,
  details?: Error | { [key: string]: string },
  hash_code: string = 'No hash code available'
) => {
  if (severity === 'error') {
    // \x1b[31m indicates that font should be red and \x1b[0m is the closing
    // eslint-disable-next-line no-console
    console.error(`\x1b[31m [${new Date().toISOString()}]: [${name}] ${message} \n - ${reason} \x1b[0m  \n`, details);
    captureEvent({
      message,
      level: severity,
      extra: {
        file: name,
        hash_code,
      },
    });
  } else {
    // \x1b[33m indicates that font should be yellow and \x1b[0m is the closing
    // eslint-disable-next-line no-console
    console.debug(`\x1b[33m [${new Date().toISOString()}]: [${name}] ${message} \n - ${reason} \x1b[0m`, details);
  }
};
const logger = {
  logEvent,
  logException,
};

export default logger;
